<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP WORK ORDER VIEW

type    : view

uses    : work-task-list-workorder-view
          schedule-dialog
          simple-dialog-template
          InventoryMap
          header-view
          main-container
          main-card
          ProgressDialog
          ErrorDialog

route   : /workorders/view/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-work-order-view" v-if="componentCreated">
    <header-view
      ref="headerView"
      title="Work Order"
      short_title=""
      v-bind:status="'workorder ' + workorder.status"
      v-bind:displayedStatus="workorder.status"
      v-bind:job_uuid="workorder.job_uuid"
      v-bind:id="'WO-' + workorder.id.toString().padStart(5, '0')"
      :allow_workorder_download="true"
      v-bind:loading_workorder_url="loadingPDF"
      @generate_download_link="generatePDF()"
      view_phase="Work"
      v-bind:btnOptions="headerButtons"
      v-bind:workorders="workorders"
      v-bind:previous_page="prevRoute" />
    <main-container>
      <main-card>
        <v-row>
          <v-col>
            <div class="title">Name</div>
            <div v-if="!editingWorkOrderName">
              {{ display_name }}
              <v-btn
                class="mt-n1"
                color="secondary"
                icon
                tile
                :ripple="false"
                x-small
                @click="editingWorkOrderName = true"
                :disabled="isWorkOrderClosed">
                <v-icon color="secondary">mdi-pencil</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-form ref="form">
                <v-text-field
                  v-model="display_name"
                  :rules="validate_work_order_name"
                  validate-on-blur
                  required />
              </v-form>
              <v-btn color="primary" text @click="onSaveWorkOrderName">
                Save
              </v-btn>
              <v-btn
                color="secondary"
                text
                @click="
                  display_name = workorder.name;
                  editingWorkOrderName = false;
                ">
                Cancel
              </v-btn>
            </div>
            <div class="title mt-3">Client</div>
            <router-link
              v-if="allowClients"
              :to="{name: 'ClientView', params: {uuid: client.uuid}}">
              <div class="subheading">{{ client.client_name }}</div>
            </router-link>
            <div class="subheading">{{ client.contact_name }}</div>
            <div class="subheading">{{ client.contact_phone }}</div>
            <div class="subheading">{{ client.contact_email }}</div>
          </v-col>
          <v-col>
            <div class="title">Job Site</div>
            <div class="subheading">{{ jobSiteName }}</div>
            <div class="subheading">{{ jobSite.address }}</div>
            <div class="subheading">
              {{ jobSite.city }}, {{ jobSite.state }} {{ jobSite.zip_code }}
            </div>
            <div class="title mt-3">Crew Notes</div>
            <div v-if="!editingWorkOrderNotes">
              <span id="notesText">{{
                display_notes || 'None Available'
              }}</span>
              <v-btn
                class="mt-n1 ml-1"
                color="secondary"
                icon
                tile
                :ripple="false"
                x-small
                @click="editingWorkOrderNotes = true"
                :disabled="isWorkOrderClosed">
                <v-icon color="secondary">mdi-pencil</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-textarea
                outlined
                v-model="display_notes"
                :disabled="isWorkOrderClosed" />
              <v-btn color="primary" text @click="onSaveWorkOrderNotes">
                Save
              </v-btn>
              <v-btn
                color="secondary"
                text
                @click="
                  display_notes = workorder.notes;
                  editingWorkOrderNotes = false;
                ">
                Cancel
              </v-btn>
            </div>
          </v-col>
          <v-col>
            <span class="title">Crew Foreman</span>
            <v-select
              :items="userOptions"
              label="Select User"
              solo
              :disabled="
                isWorkOrderClosed ||
                workorder.status == 'Completed' ||
                !allowWorkOrderAssign
              "
              v-model="workorder.user_uuid"
              @change="foremanChanged" />
            <div class="title">Scheduled Dates</div>
            <div>
              <v-chip
                v-for="date in workOrderDates.filter((date) => {
                  return date.workOrderUuid == workorder.uuid;
                })"
                v-bind:key="date.workOrderUuid">
                {{ displayDateFormat(date.start, tenantSettings.date_format) }}
              </v-chip>
            </div>
            <div class="mt-2">
              <v-btn
                v-if="allowWorkOrderSchedule"
                color="primary"
                :disabled="isWorkOrderClosed || workorder.status == 'Completed'"
                @click="openCalendarDialog">
                Schedule
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </main-card>
      <v-row class="mx-0 mt-6">
        <v-toolbar-title class="display-1">Work Tasks</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5" md="4" lg="3" class="mx-0 px-0">
          <work-task-list-workorder-view
            v-if="estimate"
            move_workorder
            @loading="showLoading()"
            @onReload="statusUpdate()"
            v-bind:workorder="workorder"
            v-bind:estimate="estimate"
            v-bind:trees="filteredTrees"
            v-bind:workorder_closed="isWorkOrderClosed"
            v-bind:tenantSettings="tenantSettings" />
        </v-col>
        <v-col class="mt-3 px-0" cols="12" sm="7" md="8" lg="9">
          <inventory-map
            v-bind:trees="filteredTrees"
            v-bind:jobsites="jobSites"
            v-bind:tenantSettings="tenantSettings"
            v-bind:selected_jobsite_uuid="workorder.job_site_uuid"
            v-bind:estimate="estimate"
            :can_add_trees="false"
            inventory_type="trees"
            :is_work_order="true"
            ref="inventoryMap" />
        </v-col>
      </v-row>
    </main-container>
    <simple-dialog-template
      :open="confirmDialog"
      :dialogTitle="dialogTitle"
      :dialogText="dialogMessage"
      dialogButtonOne="Confirm"
      :dialogButtonTwo="closeButtonText"
      @buttonOne="confirmMethod"
      @buttonTwo="confirmDialog = false" />
    <v-dialog v-model="calendarDialog" max-width="80%" persistent>
      <v-card>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Schedule Work Date"
          closeBtn
          :closeAction="
            () => {
              calendarDialog = false;
            }
          " />
        <v-card-text>
          <schedule-dialog
            v-bind:workorderEventIds="workorder.event_uuids"
            v-bind:today="today"
            v-bind:dialogFocus="focus"
            v-bind:dateObjects="calendarDates"
            @eventIdsUpdated="eventIdsUpdated"
            :canEditEvents="true"
            v-bind:editWorkOrder="workorder.uuid"
            v-bind:userList="userOptions"
            v-bind:selectedUserUUID="workorder.user_uuid" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="5000">
      Work Order successfully updated, thank you.
      <v-btn color="blue" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>

    <simple-dialog-template
      :open="notifyDialog"
      dialogTitle="Select Work Tasks"
      :dialogText="notifyDialogText"
      dialogButtonTwo="OK"
      @buttonTwo="notifyDialog = false" />

    <v-dialog v-model="invoiceDialog" width="500">
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-toolbar color="info" flat dark>
          <v-toolbar-title> Close Work Order </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p>
            All tasks have been completed in this work order, do you want to
            create an invoice for it?
          </p>
          <p>
            Once a work order has been invoiced, you can no longer make changes
            to it.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="button-primary darken-1" @click="doBuildInvoice"
            >Yes</v-btn
          >
          <v-btn color="button-secondary" @click="invoiceDialog = false"
            >No</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <progress-dialog v-model="busy" header="Processing" body="Processing">
    </progress-dialog>
    <error-dialog
      v-model="errorDialog"
      :headline="errorHeader"
      :body="errorBody">
    </error-dialog>
  </div>
</template>

<script>
  // components
  import WorkTaskListWorkorderView from '@/components/worktasks/work-task-list-workorder-view';
  import ScheduleDialog from '@/components/schedule/schedule-dialog';
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import InventoryMap from '@/components/inventory-map';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';
  import MainCard from '@/components/main-card';
  import ProgressDialog from '@/components/helpers/ProgressDialog';
  import ErrorDialog from '@/components/helpers/ErrorDialog';

  // mixins
  import Localization from '@/mixins/localization';
  import forms from '@/mixins/forms';
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';
  import configurations from '@/mixins/configurations';

  // services
  import Jobs from '@/services/Jobs.service.js';
  import Clients from '@/services/Clients.service.js';
  import Users from '@/services/Users.service.js';
  import Scheduling from '@/services/Scheduling.service.js';
  import Invoices from '@/services/Invoices.service.js';
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppWorkorderView',
    components: {
      'work-task-list-workorder-view': WorkTaskListWorkorderView,
      'schedule-dialog': ScheduleDialog,
      'simple-dialog-template': SimpleDialogTemplate,
      'inventory-map': InventoryMap,
      'header-view': HeaderView,
      'main-container': MainContainer,
      'main-card': MainCard,
      'progress-dialog': ProgressDialog,
      'error-dialog': ErrorDialog,
    },
    mixins: [forms, dateTimeHelpers, configurations, Localization],
    props: {
      // required, uuid for workorder being displayed
      uuid: {
        type: String,
        required: true,
      },
      // optional. If navigating here from a component
      // that already has the target workorder information, this
      // speeds up page load by not querying for it again.
      wo: {
        type: Object,
        default: () => {},
        required: false,
      },
    },
    data() {
      return {
        componentCreated: false,
        loadingPDF: false,
        // filter: {
        //   workorder: null,
        //   estimate: null,
        //   tenant_uuid: this.$auth.tenantProfile.uuid,
        //   client_uuid: null,
        //   job_uuid: null,
        //   workorder_uuid: null,
        //   workorder_closed: null
        // },
        errorDialog: false,
        errorHeader: '',
        errorBody: '',
        estimate: {},
        allowClients: false,
        allowInvoices: false,
        allowWorkOrderAssign: false,
        allowWOrkOrderSchedule: false,
        busy: false,
        closeButtonText: 'Close',
        invoiceDialog: false,
        dialogMessage: '',
        dialogTitle: '',
        confirmMethodName: '',
        confirmDialog: false,
        calendarDialog: false,
        today: '2019-01-08',
        focus: '2019-01-08',
        //these are the stored values for the work order

        //need to pass data from the schedule component to this one
        // https://forum.vuejs.org/t/passing-data-back-to-parent/1201
        workOrderDates: [],
        calendarDates: [],
        userOptions: [],
        snackbar: false,
        //new ones below
        crew: {},
        client: {},
        foreman_name: '',
        worktasks: [],
        workorder: {},
        workorders: [],
        trees: [],
        treeIDs: [],
        filteredTrees: [],
        notifyDialog: false,
        notifyDialogText: '',
        editingWorkOrderName: false,
        editingWorkOrderNotes: false,
        jobSites: [],
        jobSite: {},
        prevRoute: null,
        display_notes: null,
        display_name: null,
        tenantSettings: {},
      };
    },
    beforeRouteEnter(to, from, next) {
      // since its possible to route here from more than one view, this will
      // help us route the back button correctly

      next((vm) => {
        if (from && from.path && from.path != '/') {
          vm.prevRoute = from.path;
        } else {
          vm.prevRoute = '/workorders';
        }
      });
    },
    computed: {
      isWorkOrderClosed() {
        return this.workorder.status == 'Invoiced';
      },
      jobSiteName: function () {
        return this && this.jobSite.name ? this.jobSite.name : 'No Site Name';
      },
      headerButtons: function () {
        if (this.worktasks.length == 0) {
          return [
            {
              name: '',
              icon: 'mdi-delete',
              action: this.openRemoveWorkOrderDialog,
            },
          ];
        } else if (
          this.workorder.status != 'Completed' &&
          !this.isWorkOrderClosed
        ) {
          return [
            {
              name: 'Complete All',
              action: this.completeWorkOrder,
              btnColor: 'button-primary',
            },
            {
              name: '',
              icon: 'mdi-delete',
              action: this.openRemoveWorkOrderDialog,
              disabled: this.worktasks.length != 0,
            },
          ];
        } else if (this.workorder.status === 'Completed') {
          return [
            {
              name: 'Create Invoice',
              action: this.doBuildInvoice,
              btnColor: 'button-primary',
            },
            {
              name: '',
              icon: 'mdi-delete',
              action: this.openRemoveWorkOrderDialog,
              disabled: this.worktasks.length != 0,
            },
          ];
        } else {
          return [];
        }
      },
    },
    watch: {},

    /**************************************************/
    //                    CREATED
    /**************************************************/
    async created() {
      this.allowClients = this.$auth.isAllowed('clients', 'view');
      this.allowWorkOrderAssign = this.$auth.isAllowed('workorders', 'assign');
      this.allowWorkOrderSchedule = this.$auth.isAllowed(
        'workorders',
        'schedule'
      );
      this.allowInvoices = this.$auth.isAllowed('invoices', 'create');

      // set up component
      this.setup();
    },
    methods: {
      uploadClientToQuickbooks: async function () {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // create new or sync existing quickbooks client
        const resp = await Tenants.createQuickbooksClient(
          this.client,
          accessToken
        );

        if (resp && resp.quickbooks_id) {
          return resp.quickbooks_id;
        } else {
          return null;
        }
      },
      async setup() {
        // verify uuid was passed in
        if (!this.uuid) {
          console.log('empty uuid passed into workorderview');
        }

        // get tenant settings
        // (in an authenticated situation we already have this info
        // via tenantProfile that gets loaded in $auth during the
        // begging of the user's session. This special case
        // is needed for the connected components that need this info
        // passed in for unsecured estimate proposal links)
        this.tenantSettings = this.$auth.tenantProfile
          ? this.$auth.tenantProfile
          : await Tenants.getSettingsInfo(
              this.$auth.userProfile.tenant_uuid
              // unsecured route
            );

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // set workorder if one was passed in
        // (this will be the case if navigating from workorder list view)
        // otherwise, load workorder from database
        this.workorder = this.wo
          ? this.wo
          : await Jobs.getWorkOrder(this.uuid, accessToken);

        // console.log('workorder: ', this.workorder);

        // vital for updating status in header on work task interaction
        this.$events.$on('worktaskreload', this.forceReload);

        if (this.workorder) {
          // set field values for editable display (we do this so we can return the
          // user to the previous value of the field if they click cancel)
          this.display_notes = this.workorder.notes;
          this.display_name = this.workorder.name;

          // load events
          await this.loadEvents();

          // update filter with job uuid
          let filter = {
            tenant_uuid: this.$auth.tenantProfile.uuid,
            job_uuid: this.workorder.job_uuid,
          };

          this.worktasks = await Jobs.getWorkTasks(
            {
              workorder_uuid: this.uuid,
            },
            accessToken
          );

          // get all work orders based on the job uuid listed for the single work order we retrieved above
          this.workorders = await Jobs.getAllWorkOrders(filter, accessToken);

          // get client
          this.client = await Clients.getClient(
            this.workorder.client_uuid,
            accessToken
          );

          // get estimate
          this.estimate = await Jobs.getEstimateByJobUUID(
            this.workorder.job_uuid,
            accessToken
          );

          // get job site
          this.jobSite = await Clients.getJobSite(
            this.workorder.job_site_uuid,
            accessToken
          );

          // inventory map expects a list of job sites and we only want to display one, so
          // add it to a list object
          this.jobSites = [this.jobSite];

          // load users list (crew/foremen)
          this.users = await Users.getUsersByTenant(
            this.workorder.tenant_uuid,
            accessToken
          );

          // format for users dropdown, include only active users
          if (this.users) {
            this.userOptions = this.users
              .map((user) => {
                return {
                  text: user.name,
                  value: user.uuid,
                  active: user.status != 'Inactive',
                };
              })
              .filter((user) => user.active);
          }

          // get trees for job site
          this.trees = await Clients.getTreesByJobSite(
            this.workorder.job_site_uuid
          );

          // format trees for view
          if (this.trees) {
            this.filteredTrees = this.trees;

            this.treeIDs = this.trees.map((tree) => {
              return tree.uuid;
            });
          }

          // status update
          await this.statusUpdate();

          // mark component as created
          this.componentCreated = true;

          // generate pdf
          // this.generatePDF();
        } else {
          console.log('could not get work order');
        }
      },
      // updates workorder status, works on incorrectly updated workorders, too,
      // to bring them up to speed.
      statusUpdate: async function () {
        // console.log( "hello from workorder status update!" )

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // get work task status for trees
        if (this.treeIDs) {
          await this.getWorkTaskStatus(accessToken);
        }

        // get current workorder status
        let current = this.workorder.status;

        // update work task list ( essential for work-task-list.vue )
        this.worktasks = await Jobs.getWorkTasks(
          {
            workorder_uuid: this.workorder.uuid,
          },
          accessToken
        );

        // get new status (checks against new worktasks)
        let newStatus = this.workOrderStatus();

        // validate we have values to change
        if (!(this.workorder && this.workorder.uuid && newStatus)) {
          return;
        }

        //   // closed workorders can't change status
        if (this.isWorkOrderClosed) {
          return;
        }

        if (newStatus == 'complete') {
          this.workorder.status = 'Completed';
        } else if (newStatus == 'in_progress') {
          this.workorder.status = 'In Progress';
        } else if (newStatus == 'scheduled') {
          this.workorder.status = 'Scheduled';
        } else if (newStatus == 'not_scheduled') {
          this.workorder.status = 'Not Scheduled';
        } else {
          this.workorder.status = 'No Work Tasks';
        }

        // update only if we actually changed it
        if (current != this.workorder.status) {
          // Get the access token from the auth wrapper
          const accessToken = await this.$auth.getTokenSilently();

          // update work order through api
          await Jobs.updateWorkOrder(
            this.workorder.uuid,
            {
              status: this.workorder.status,
            },
            accessToken
          );
          // this.generatePDF();
        }
      },
      workOrderStatus: function () {
        // console.log( "hello from CALCULATED WORKORDERSTATUS!" )

        let hasComplete = false;
        let hasIncomplete = false;

        if (this.workorder && this.workorder.status == 'Invoiced') {
          return 'invoiced';
        }

        if (!this.worktasks || this.worktasks.length == 0) {
          return 'unknown';
        }

        // check status of each worktask
        for (let i = 0; i < this.worktasks.length; i++) {
          if (this.worktasks[i].status != 'complete') {
            hasIncomplete = true;
          } else {
            hasComplete = true;
          }
        }

        let status = 'complete';

        if (hasIncomplete && hasComplete) {
          status = 'in_progress';
        } else if (hasIncomplete) {
          if (
            this.workorder.event_uuids &&
            this.workorder.event_uuids.length > 0
          ) {
            status = 'scheduled';
          } else {
            status = 'not_scheduled';
          }
        }

        // console.log( "new status: ", status )

        return status;
      },
      // workOrderStatus: function () {
      //   // console.log( "hello from workorderstatus function!" )
      //
      //   let hasComplete = false
      //   let hasIncomplete = false
      //
      //   if ( !this.worktasks || this.worktasks.length == 0 ) {
      //     return "unknown"
      //   }
      //
      //   // check status of each work task
      //   for ( let i = 0; i < this.worktasks.length; i++ ) {
      //     if ( this.worktasks[ i ].status != 'complete' ) {
      //       hasIncomplete = true
      //     } else {
      //       hasComplete = true
      //     }
      //   }
      //
      //   let status = "complete"
      //
      //   if ( hasIncomplete && hasComplete ) {
      //     status = "in_progress"
      //   } else if ( hasIncomplete ) {
      //     if ( this.workorder.event_uuids && this.workorder.event_uuids.length > 0 ) {
      //       status = "scheduled"
      //     } else {
      //       status = "not_scheduled"
      //     }
      //   }
      //
      //   return status
      // },

      async forceReload() {
        // console.log('AppWorkorderView : forceReload');
        this.setup();
      },
      showLoading() {},
      doBuildInvoice: async function () {
        this.busy = true;

        // try {
        await this.buildInvoice();

        // this.busy = false
        // this.$router.push( '/invoices/view/' + this.invoice.uuid )
        // } catch ( error ) {
        //   switch ( error.errorType ) {
        //     case "validation":
        //       this.notifyDialogText = error.errorMessage
        //       this.notifyDialog = true
        //       break
        //     case "error":
        //       this.errorHeader = "QuickBooks Error"
        //       this.errorBody = error.errorMessage
        //       this.errorDialog = true
        //       this.busy = false
        //       break
        //     default:
        //       throw new Error( "Unhandled buildInvoice errorType", error );
        //       break
        //   }
        //   return
        // }
      },

      async initiateInvoice(invoiceParams) {
        this.invoice = Invoices.blankInvoice();

        // assign customer quickbooks id to new invoice or
        // upload client to quickbooks (for cases where quickbooks is enabled
        // but an invoice is being made for a customer that has yet to be synced up)
        if (
          this.configuration &&
          this.configuration.quickbooks &&
          this.$auth.tenantProfile.quickbooks_enabled
        ) {
          if (this.client.quickbooks_id) {
            this.invoice.client_quickbooks_id = this.client.quickbooks_id;
          } else {
            this.invoice.client_quickbooks_id =
              await this.uploadClientToQuickbooks();
          }
        }

        this.invoice.tenant_uuid = invoiceParams.tenant_uuid;
        this.invoice.client_uuid = invoiceParams.client_uuid;
        this.invoice.job_uuid = invoiceParams.job_uuid;
        this.invoice.client_name = this.client.client_name;
        this.invoice.client_address = this.client.address;
        this.invoice.client_city = this.client.city;
        this.invoice.client_state = this.client.state;
        this.invoice.client_postal_code = this.client.zip_code;
        this.invoice.client_quickbooks_id =
          this.configuration && this.configuration.quickbooks
            ? this.client.quickbooks_id
            : null;
        this.invoice.tenant_name = this.$auth.tenantProfile.invoice_name;
        this.invoice.tenant_address = this.$auth.tenantProfile.invoice_address;
        this.invoice.tenant_city = this.$auth.tenantProfile.invoice_city;
        this.invoice.tenant_state = this.$auth.tenantProfile.invoice_state;
        this.invoice.tenant_postal_code =
          this.$auth.tenantProfile.invoice_postal_code;
        this.invoice.tenant_phone = this.$auth.tenantProfile.contact_phone;
        this.invoice.terms = this.$auth.tenantProfile.invoice_terms;
        this.invoice.notes = this.$auth.tenantProfile.invoice_notes;
        this.invoice.tax_rate = this.$auth.tenantProfile.tax_rate;
        this.invoice.status = 'open';
        let issue_date = new Date();
        this.invoice.issue_date = issue_date;
        return this.invoice;
      },
      async buildInvoice() {
        let invoiceParams = {
          tenant_uuid: this.workorder.tenant_uuid,
          client_uuid: this.workorder.client_uuid,
          job_uuid: this.workorder.job_uuid,
        };

        let workorders = [this.workorder];

        const accessToken = await this.$auth.getTokenSilently();

        //make sure at least one workorder was selected
        if (workorders.length == 0) {
          return Promise.reject({
            errorType: 'validation',
            errorMessage:
              'You must select at least one completed workorder to create an invoice.',
          });
        }

        //make sure all selected workorders are completed
        for (let i = 0; i < workorders.length; i++) {
          if (workorders[i].status != 'Completed') {
            return Promise.reject({
              errorType: 'validation',
              errorMessage: 'Only completed workorders may be invoiced.',
            });
          }
        }

        //sets up this.invoice
        await this.initiateInvoice(invoiceParams);

        let invoice_items = [];

        // pull work tasks for all workorders selected
        for (let i = 0; i < workorders.length; i++) {
          //load all completed work tasks for this workorder.
          let queryParams = {
            workorder_uuid: workorders[i].uuid,
            status: 'complete',
          };

          let worktasks = await Jobs.getWorkTasks(queryParams, accessToken);

          for (let w = 0; w < worktasks.length; w++) {
            // create invoice items for all work tasks pulled
            let amount = this.getWorkTaskTotal(worktasks[w]);
            let description =
              worktasks[w].workcategory +
              ' ' +
              worktasks[w].worktype +
              ' ' +
              worktasks[w].notes;
            let invoice_item = Invoices.blankInvoiceItem();

            invoice_item.description = description;
            invoice_item.amount = amount;
            invoice_item.tenant_uuid = this.invoice.tenant_uuid;
            invoice_item.client_uuid = this.invoice.client_uuid;
            invoice_item.job_uuid = this.invoice.job_uuid;
            invoice_item.invoice_uuid = this.invoice.uuid;

            invoice_items.push(invoice_item);
          }
        }

        let invoiceRes = await Invoices.createInvoice(
          this.invoice,
          accessToken
        );

        if (invoiceRes) {
          let invoiceItemRes = await Invoices.bulkCreateInvoiceItems(
            {
              invoice_items: invoice_items,
              tenant_uuid: this.invoice.tenant_uuid,
              client_uuid: this.invoice.client_uuid,
              invoice_uuid: this.invoice.uuid,
              quickbooks_id:
                this.configuration && this.configuration.quickbooks
                  ? this.client.quickbooks_id
                  : null,
              client: {
                address: this.invoice.client_address,
                city: this.invoice.client_city,
                state: this.invoice.client_state,
                zip_code: this.invoice.client_postal_code,
              },
            },
            accessToken
          );

          if (invoiceItemRes) {
            //Link and close the associated workorders
            for (let i = 0; i < workorders.length; i++) {
              //close workorder
              this.workorder = workorders[i];
              this.workorder.invoice_uuid = this.invoice.uuid;
              this.workorder.status = 'Invoiced';

              // update work order through api
              await Jobs.updateWorkOrder(
                this.workorder.uuid,
                this.workorder,
                accessToken
              );
            }

            await Jobs.updateJobPhase(
              invoiceParams.job_uuid,
              'Invoice'
              // unsecured route
            );

            this.busy = false;
            this.$router.push({
              name: 'InvoiceView',
              params: {
                uuid: this.invoice.uuid,
              },
            });
          }
        }
      },

      completeWorkOrder: async function () {
        this.busy = true;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // load all associated worktasks
        this.worktasks = await Jobs.getWorkTasks(
          {
            workorder_uuid: this.workorder.uuid,
          },
          accessToken
        );

        if (this.worktasks) {
          for (let i = 0; i < this.worktasks.length; i++) {
            if (this.worktasks[i].status != 'complete') {
              this.worktasks[i].status = 'complete';
              await Jobs.updateWorkTask(
                this.worktasks[i].uuid,
                this.worktasks[i],
                accessToken
              );
            }
          }
        }

        this.workorder.status = 'Completed';

        // update work order through api
        let res = await Jobs.updateWorkOrder(
          this.workorder.uuid,
          this.workorder,
          accessToken
        );

        // Load work order after updating
        if (res) {
          this.workorder = await Jobs.getWorkOrder(this.uuid, accessToken);
          this.$events.$emit('worktaskreload');
          this.busy = false;
        } else {
          console.log('could not update work order to completed');
          this.busy = false;
        }
      },
      async getWorkTaskStatus(accessToken) {
        let data = {
          treeIDs: this.treeIDs,
          workOrderID: this.workorder.uuid,
        };

        let statusResults = await Jobs.getStatusByTrees(data, accessToken);

        //get the results back and attach the status to the filtered tree object
        this.filteredTrees.forEach((tree) => {
          statusResults.forEach((workTask) => {
            if (tree.uuid == workTask.tree_uuid) {
              tree.workTaskStatus = workTask.status;
            }
          });
        });

        if (this.$refs.inventoryMap) {
          this.$refs.inventoryMap.updateTrees();
        }
      },
      openRemoveWorkOrderDialog: function () {
        this.dialogMessage =
          "Are you sure you'd like to permanently remove this work order?";
        this.dialogTitle = 'Delete Work Order';
        this.confirmMethodName = 'removeWorkOrder';
        this.closeButtonText = 'Cancel';
        this.confirmDialog = true;
      },
      confirmMethod: function () {
        this.confirmDialog = false;
        if (this.confirmMethodName == 'removeWorkOrder') {
          this.removeWorkOrder();
        }
      },
      removeWorkOrder: async function () {
        if (this.workorder) {
          // Get the access token from the auth wrapper
          const accessToken = await this.$auth.getTokenSilently();

          // load all associated worktasks
          // (should we be removing these tasks too?)
          this.worktasks = await Jobs.getWorkTasks(
            {
              workorder_uuid: this.workorder.uuid,
            },
            accessToken
          );

          // delete work order events
          if (
            this.workorder.event_uuids &&
            this.workorder.event_uuids.length > 0
          ) {
            await Scheduling.deleteWorkOrderEvents(
              this.workorder.event_uuids,
              accessToken
            );
          }

          // delete work order
          await Jobs.deleteWorkOrder(this.workorder.uuid, accessToken);
          this.$router.push({
            name: 'JobView',
            params: {
              uuid: this.workorder.job_uuid,
            },
          });
        } else {
          console.log("can't remove workorder, no workorder is set");
        }
      },
      async foremanChanged() {
        // console.log( "foremanChanged" )

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // update work order through api
        await Jobs.updateWorkOrder(
          this.workorder.uuid,
          {
            user_uuid: this.workorder.user_uuid,
          },
          accessToken
        );

        await Scheduling.updateForeman(this.workorder, accessToken);
        this.snackbar = true;

        await this.loadEvents();

        // status update
        await this.statusUpdate();

        // this.generatePDF();
      },
      openCalendarDialog: function () {
        this.today = this.toCalendarFriendlyString(new Date());
        this.focus = this.toCalendarFriendlyString(new Date());
        this.calendarDialog = true;
      },
      cancelCalendar: function () {
        //this.type = 'month'
        this.calendarDialog = false;
        // this.calendarDates = this.workOrderDates
      },
      toCalendarFriendlyString: function (date) {
        return (
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1) +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          ':' +
          date.getMinutes()
        );
      },
      async eventIdsUpdated(newEventIds) {
        // console.log( "eventIdsUpdated" )
        this.workorder.event_uuids = newEventIds;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // update work order through api
        await Jobs.updateWorkOrder(
          this.workorder.uuid,
          {
            event_uuids: this.workorder.event_uuids,
          },
          accessToken
        );

        this.loadEvents();

        // status update
        await this.statusUpdate();

        // this.generatePDF();
      },
      async onSaveWorkOrderName() {
        // console.log( "onSaveWorkOrderName" )

        // validate field
        if (!this.$refs.form.validate()) {
          return false;
        }

        // update main workoder object to the user updated display value
        this.workorder.name = this.display_name;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // update work order through api
        await Jobs.updateWorkOrder(
          this.workorder.uuid,
          {
            name: this.workorder.name,
          },
          accessToken
        );

        // we need to update the workorder name for the header-view workorders (wo) button dropdown
        // find workorder we updated in the workorders list :
        this.workorders.find((x) => x.uuid === this.workorder.uuid).name =
          this.workorder.name;

        // regenerate pdf
        // this.generatePDF();

        // this just changes how the edit vs the regular display for this
        // field looks
        this.editingWorkOrderName = false;
      },
      async onSaveWorkOrderNotes() {
        // console.log( "onSaveWorkOrderNotes" )

        // todo: add validation to this field

        // update main workoder object to the user updated display value
        this.workorder.notes = this.display_notes;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // update work order through api
        await Jobs.updateWorkOrder(
          this.workorder.uuid,
          {
            notes: this.workorder.notes,
          },
          accessToken
        );

        // this may not be necessary, since we only use the workorders list for the
        // names for header-view, but just in case for consistency update the
        // associated workorders list item with the new notes:
        this.workorders.find((x) => x.uuid === this.workorder.uuid).notes =
          this.workorder.notes;

        // regenerate pdf
        // this.generatePDF();

        // this just changes how the edit vs the regular display for this
        // field looks
        this.editingWorkOrderNotes = false;
      },

      async generatePDF() {
        // console.log("generate pdf called! ")

        // prevent multiple generations of pdf while work order data is loading
        if (!this.componentCreated) {
          return;
        }

        // show pdf is loading in header view
        this.loadingPDF = true;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        Jobs.getWorkOrderSnapshot(this.workorder.uuid, accessToken).then(
          (res) => {
            // console.log(res)
            if (res && res.location) {
              this.$refs.headerView.onDownloadLinkReady(res.location);
              this.loadingPDF = false;
            }
          }
        );
      },
      // load events for calendar
      async loadEvents() {
        // console.log( "loading events" )

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // get events
        let res_events = await Scheduling.getEvents(
          {
            tenant_uuid: this.$auth.userProfile.tenant_uuid,
            start_time: '2019-02-10',
          },
          accessToken
        );

        if (res_events) {
          // get workorders
          let res_workorders = await Jobs.getWorkOrders(
            {
              tenant_uuid: this.$auth.userProfile.tenant_uuid,
            },
            accessToken
          );

          if (res_workorders) {
            let workorders = res_workorders;

            this.workOrderDates = res_events.map(
              function (event) {
                // console.log( "event: ", event )
                let eventToAddToCalendar = {
                  name: 'Work Order',
                  start: this.toCalendarFriendlyString(
                    new Date(event.start_time.split('.')[0])
                  ),
                  end: this.toCalendarFriendlyString(
                    new Date(event.end_time.split('.')[0])
                  ),
                  formattedStartTime: this.formatTime(
                    new Date(event.start_time.split('.')[0]),
                    this.tenantSettings.time_format
                  ),
                  details: 'heres details',
                  color: 'blue',
                  eventUuid: event.uuid,
                  startTimeOriginalFormat: event.start_time,
                  endTimeOriginalFormat: event.end_time,
                  foreman_uuid: event.foreman_uuid,
                };

                workorders.forEach((order) => {
                  if (order.event_uuids) {
                    order.event_uuids.forEach((event_uuid) => {
                      // each event id should only be in one work order's list of ids
                      if (event_uuid == event.uuid) {
                        eventToAddToCalendar.clientUuid = order.client_uuid;
                        eventToAddToCalendar.workOrderUuid = order.uuid;
                        eventToAddToCalendar.clientName =
                          this.client.client_name;

                        if (order.user_uuid) {
                          eventToAddToCalendar.foreman_uuid = order.user_uuid;
                        }
                      }
                    });
                  }
                });
                return eventToAddToCalendar;
              }.bind(this)
            );

            this.calendarDates = this.workOrderDates;
          } else {
            console.log('could not load work orders');
          }
        } else {
          console.log('could not load events');
        }
      },
      getWorkTaskSubTotal(entry) {
        //returns work task subtotal, which is the amount before discounts are applied.
        let subtotal = 0;
        //manhour amount calculation
        if (entry.pricing_method == 'HOURLY') {
          //cost times units
          subtotal = entry.cost * entry.units;
        }
        //dbh amount calculation
        else if (entry.pricing_method == 'DBH') {
          //cost times dbh, times units
          subtotal = entry.cost * entry.dbh * entry.units;
        }
        //height amount calculation
        else if (entry.pricing_method == 'HEIGHT') {
          //cost times height, times units
          subtotal = entry.cost * entry.height * entry.units;
        }
        //quickprice amount calculation
        else if (entry.pricing_method == 'FLAT') {
          subtotal = entry.cost * entry.units;
        }
        //openbid amount calculation
        else if (entry.pricing_method == 'OPENBID') {
          subtotal = entry.cost * entry.units;
        }
        return subtotal;
      },
      getWorkTaskTotal(entry) {
        let total = 0;
        total = this.getWorkTaskSubTotal(entry) - entry.discount;
        return total;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .clientList {
    text-align: left;
    width: 40%;
    margin: 0 auto;
  }
  #estimateHeaderRow {
    min-height: 12em;
  }
  .mapToggleSwitch {
    display: block;
  }
  #notesText {
    word-wrap: break-word;
  }
</style>
