<!-- ----------------------------------------------------------------------- -->
<!--
name    :  WORK TASK LIST WORKORDER VIEW

about   :  this is used in workorder view to list the worktasks for a specific
           workorder. This does NOT split worktasks into sections.

type    :  component

props:  :

used by :  AppWorkorderView

uses    :  work-order-create-dialog
           simple-dialog-template
           work-task
           header-view

route   :  none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-container class="work-task-list-workoder-view">
    <div class="work-task-panel">
      <v-card class="work-task-panel-v-card">
        <!-- line divider -->
        <v-divider v-if="worktasks && worktasks.length > 0" />

        <!-- list of tasks, each task object getting 3 lines to display -->
        <v-list three-line class="py-0">
          <div
            class="ma-0 pa-0"
            v-for="(task, index) in worktasks"
            :key="task.uuid">
            <v-list-item
              class="px-1 ml-n2"
              v-if="task.workcategory && task.worktype">
              <!-- This is the move worktask option & menu of other workorders that the workorder can be moved to -->
              <v-list-item-action class="mr-1 mt-n1 pt-0" v-if="move_workorder">
                <v-menu offset-y min-width="150" class="white">
                  <template v-slot:activator="{on}">
                    <v-btn
                      small
                      color="primary"
                      v-on="on"
                      class="ma-3"
                      icon
                      :disabled="workorder_closed">
                      <v-icon> mdi-arrow-left-bold-hexagon-outline</v-icon>
                    </v-btn>
                  </template>
                  <div class="title white pt-2 pl-2">Move To:</div>
                  <v-list>
                    <v-list-item
                      v-for="order in eligibleWorkorders"
                      :key="order.uuid"
                      :disabled="workorder_closed"
                      @click="moveWorkTask(task, order.uuid)">
                      <v-list-item-title>
                        {{ order.name }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showNewWorkOrderDialog(task)">
                      <v-btn class="button-primary">
                        <v-icon>mdi-plus</v-icon>
                        New Work Order
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
              <v-list-item-action v-else class="mr-1">
                <v-menu offset-y min-width="150" class="white">
                  <template>
                    <v-btn small color="primary" icon> </v-btn>
                  </template>
                </v-menu>
              </v-list-item-action>
              <v-list-item-content class="pa-0">
                <work-task
                  v-model="worktasks[index]"
                  summary
                  @save="forceReload()"
                  @loading="
                    () => {
                      $emit('loading');
                    }
                  "
                  :proposal="proposal"
                  :workorder_uuid="workorder ? workorder.uuid : null"
                  :workorder_closed="workorder_closed"
                  :pricing_method="task.pricing_method"
                  v-bind:tenantSettings="tenantSettings">
                </work-task>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="worktasks.length - 1 != index"></v-divider>

            <!-- .................................................................... -->
            <!-- .................. WORK ORDER CREATE DIALOG ........................ -->
            <!-- .................................................................... -->
            <work-order-create-dialog
              :workorder="newWorkOrder"
              v-model="isNewWorkOrderDialogVisible"
              @save="moveWorkTaskToNewWorkOrder(task)" />
          </div>
        </v-list>
      </v-card>
    </div>
    <div class="estimate-totals" v-if="tenant">
      <v-container style="padding-right: 32px"> </v-container>
    </div>

    <simple-dialog-template
      :open="notifyDialog"
      dialogTitle="Notice"
      dialogButtonOne="OK"
      :dialogText="notifyDialogText"
      @buttonOne="notifyDialog = false" />
  </v-container>
</template>

<script>
  // components
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import WorkTask from '@/components/worktasks/work-task';
  import HeaderView from '@/components/header-view';
  import WorkOrderCreateDialog from '@/components/workorders/work-order-create-dialog';

  // mixins
  import Localization from '@/mixins/localization';

  // services
  import Jobs from '@/services/Jobs.service.js';
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'WorkTaskListWorkorderView',
    components: {
      'work-order-create-dialog': WorkOrderCreateDialog,
      'simple-dialog-template': SimpleDialogTemplate,
      'work-task': WorkTask,
      // eslint-disable-next-line vue/no-unused-components
      'header-view': HeaderView,
    },
    mixins: [Localization],
    props: {
      proposal: {
        type: Boolean,
        default: false,
      },
      move_workorder: {
        //
        type: Boolean,
        default: false,
      },
      trees: {
        type: Array, // used for showing species associated to work tasks
        default: () => [],
      },
      approved: {
        // used only for allowing approved checkboxes to function until the estimate has been approved
        type: Boolean,
        default: false,
      },
      tree_uuid: {
        default: '',
        type: String,
      },
      dbh: {
        default: '',
        type: String,
      },
      height: {
        default: '',
        type: String,
      },
      workorder: {
        type: Object,
        default: () => {},
      },
      estimate: {
        type: Object,
        default: () => {},
      },
      workorder_closed: {
        default: null,
        type: Boolean,
      },
      tenantSettings: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        addWorkTaskDialog: false,
        catalogSelected: false,
        currentWorkTask: {},
        deleteUUID: '',
        estimateSection: {},
        estimateSections: [],
        estimateSubtotal: '0.00',
        isNewWorkOrderDialogVisible: false,
        newWorkOrder: {},
        notifyDialog: false,
        notifyDialogText: '',
        panels: [],
        sectionDialog: false,
        sectionsPanel: [],
        sectionTotals: [],
        selectedSection: '',
        selectedWorkCategory: '',
        selectedWorkType: '',
        showCatalogDescriptions: false,
        speciesList: [],
        tenant: null,
        workcategories: [],
        workcategory: null,
        workorders: [],
        worktaskDeleteConfirmDialog: false,
        worktasks: [],
        workTaskToggle: 0,
        worktype: null,
        worktypes: [],
      };
    },
    computed: {
      eligibleWorkorders() {
        return this.workorders.filter((order) => {
          return (
            order.uuid != this.workorder.uuid && order.status != 'Invoiced'
          );
        });
      },
      estimateTotal() {
        if (this.proposal) {
          var tax_rate = this.tenant.tax_rate ? this.tenant.tax_rate * 0.01 : 0;
          var total =
            Number.EPSILON +
            parseFloat(this.estimateSubtotal) +
            this.estimateSubtotal * tax_rate;
          var roundedTotal = Math.round(total * 100) / 100;
          return roundedTotal.toFixed(2);
        } else {
          return this.estimateSubtotal;
        }
      },
      taxRate() {
        var tax_type = this.tenant.tax_type
          ? ' (' + this.tenant.tax_type + ')'
          : '';
        var tax_rate = this.tenant.tax_rate ? this.tenant.tax_rate : '0.0';
        return tax_type + ': ' + tax_rate;
      },
    },
    watch: {
      trees() {
        this.matchWorkTasksAndSpecies();
      },
      tree_uuid() {
        this.forceReload();
      },
    },
    async created() {
      // all components that use this component must pass in an estimate
      if (!this.estimate) {
        console.log(
          'work-task-list: estimate not passed in! all views that use this component must pass in an estimate'
        );
        return;
      }

      // validate that we can get the tenant uuid
      var tenant_uuid = this.estimate.tenant_uuid
        ? this.estimate.tenant_uuid
        : this.$auth.userProfile.tenant_uuid;

      // console.log( "work-task-list: tenant_uuid: ", tenant_uuid )

      if (!tenant_uuid) {
        console.log('could not get tenant uuid, aborting...');
        return;
      }
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // Get tenant
      this.tenant = await Tenants.getTenant(tenant_uuid, accessToken);

      // console.log( "work-task-list: this.tenant: ", this.tenant )

      // Get tenant species (must be done before loadWorkTasks)
      await this.loadTenantSpecies(tenant_uuid);

      // set up new worktask object
      this.currentWorkTask = Jobs.blankWorkTask(
        tenant_uuid,
        this.estimate.client_uuid,
        this.estimate.job_uuid,
        this.estimate.uuid,
        this.tree_uuid || null,
        this.workorder ? this.workorder.uuid : null,
        this.dbh || null,
        this.height || null
      );

      // console.log( "current work task mounted: ", this.currentWorkTask )
      // console.log( "current workorder: ", this.workorder )

      // Get worktasks
      await this.loadWorkTasks(accessToken);

      // Get estimate sections
      await this.loadEstimateSections(accessToken);

      // get work orders
      await this.loadWorkOrders(accessToken);

      this.updateTotals();

      this.$events.$on('worktaskreload', this.forceReload);
    },
    methods: {
      async loadWorkTasks(accessToken) {
        // console.log( "work-task-list: tenant: ", this.tenant )
        this.worktasks = await Jobs.getWorkTasks(
          {
            tenant_uuid: this.tenant.uuid
              ? this.tenant.uuid
              : this.$auth.userProfile.tenant_uuid,
            client_uuid: this.estimate.client_uuid,
            job_uuid: this.estimate.job_uuid,
            estimate_uuid: this.estimate.uuid,
            tree_uuid: this.tree_uuid,
            workorder_uuid: this.workorder ? this.workorder.uuid : null,
          },
          accessToken
        );

        // get species list for the species column of table
        this.matchWorkTasksAndSpecies();
      },
      async loadWorkOrders(accessToken) {
        var res_work_orders = await Jobs.getAllWorkOrders(
          {
            tenant_uuid: this.tenant.uuid
              ? this.tenant.uuid
              : this.$auth.userProfile.tenant_uuid,
            client_uuid: this.estimate.client_uuid,
            job_uuid: this.estimate.job_uuid,
          },
          accessToken
        );
        // set global value if valid
        if (res_work_orders) {
          // console.log( res_work_orders );
          this.workorders = res_work_orders;
        }
      },
      async loadEstimateSections(accessToken) {
        let params = {};
        if (this.estimate.uuid) {
          params.estimate_uuid = this.estimate.uuid;
        }

        var res = await Jobs.getEstimateSections(params, accessToken);

        if (res) {
          this.estimateSections = res;
          // if ( this.estimateSections.length > 0 ) {
          //   this.selectedSection = this.estimateSections[ 0 ].uuid;
          // }
          // console.log(this.estimateSections, res)
        } else {
          this.estimateSections = [];
        }
      },
      updateTotals: function () {
        this.estimateSubtotal = this.getSubTotals();
        this.getSectionTotals();
      },
      getEstimateTotal: function () {
        let total = 0;
        var i = 0;
        var x = 0;
        for (i = 0; i < this.estimateSections.length; i++) {
          for (x = 0; x < this.worktasks.length; x++) {
            if (
              this.worktasks[x].estimate_section_uuid ==
              this.estimateSections[i].uuid
            ) {
              total =
                total +
                Number.parseFloat(this.getWorkTaskTotal(this.worktasks[x]));
            }
          }
        }
        return total;
      },
      getWorkTaskTotal(entry) {
        var total = 0;
        total = this.getWorkTaskSubTotal(entry) - entry.discount;
        return total;
      },
      getWorkTaskSubTotal(entry) {
        //returns work task subtotal, which is the amount before discounts are applied.
        var subtotal = 0;
        //manhour amount calculation
        if (entry.pricing_method == 'HOURLY') {
          //cost times units
          subtotal = entry.cost * entry.units;
        }
        //dbh amount calculation
        else if (entry.pricing_method == 'DBH') {
          //cost times dbh, times units
          subtotal = entry.cost * entry.dbh * entry.units;
        }
        //height amount calculation
        else if (entry.pricing_method == 'HEIGHT') {
          //cost times height, times units
          subtotal = entry.cost * entry.height * entry.units;
        }
        //quickprice amount calculation
        else if (entry.pricing_method == 'FLAT') {
          subtotal = entry.cost * entry.units;
        }
        //openbid amount calculation
        else if (entry.pricing_method == 'OPENBID') {
          subtotal = entry.cost * entry.units;
        }
        return subtotal;
      },
      getPreviewEstimateTotal: function () {
        let total = 0;
        var i = 0;
        var x = 0;
        for (i = 0; i < this.estimateSections.length; i++) {
          if (this.proposal) {
            if (this.estimateSections[i].approved) {
              for (x = 0; x < this.worktasks.length; x++) {
                if (
                  this.worktasks[x].estimate_section_uuid ==
                  this.estimateSections[i].uuid
                ) {
                  total =
                    total +
                    Number.parseFloat(this.getWorkTaskTotal(this.worktasks[x]));
                }
              }
            }
          }
        }
        return total;
      },
      getSubTotals: function () {
        let total = 0;
        if (this.proposal) {
          total = this.getPreviewEstimateTotal();
        } else {
          total = this.getEstimateTotal();
        }
        return total.toFixed(2);
      },

      getSectionTotals: function () {
        var totals = [];
        var i = 0;
        var x = 0;
        for (i = 0; i < this.estimateSections.length; i++) {
          let total = 0;
          for (x = 0; x < this.worktasks.length; x++) {
            if (
              this.worktasks[x].estimate_section_uuid ==
              this.estimateSections[i].uuid
            ) {
              total =
                total +
                Number.parseFloat(this.getWorkTaskTotal(this.worktasks[x]));
            }
          }
          totals.push(total.toFixed(2));
        }

        this.sectionTotals = totals;
      },

      async forceReload() {
        // console.log( "forceReload WorkTaskList" )

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        //forces a reload, in case another component needs to trigger a refresh in sibling components
        //called by an event watcher in the mounted section

        await this.loadWorkTasks(accessToken);

        this.updateTotals();

        // this is used only in the AppWorkorderView and inventory map components.
        // It is responsible for updating status in the header view after changes to
        // individual worktasks
        this.$emit('onReload');
      },
      async moveWorkTask(task, workorder_uuid) {
        this.$emit('loading');

        const accessToken = await this.$auth.getTokenSilently();

        this.currentWorkTask = task;
        this.currentWorkTask.workorder_uuid = workorder_uuid;
        var res = await Jobs.updateWorkTask(task.uuid, task, accessToken);

        if (res) {
          this.$events.$emit('worktaskreload');
        }
      },
      showNewWorkOrderDialog() {
        this.newWorkOrder = Jobs.duplicateWorkOrder(this.workorder);
        this.newWorkOrder.notes = this.workorder.notes;
        this.isNewWorkOrderDialogVisible = true;
      },
      async moveWorkTaskToNewWorkOrder(task) {
        // reload work orders because dialog just created a new one
        this.$emit('loading');

        // add new workorder to our workorder list
        this.workorders.push(this.newWorkOrder);

        // notify other components of changes
        this.$events.$emit('workOrdersChanged', this.workorders);

        // move worktask to the new workorder
        this.moveWorkTask(task, this.newWorkOrder.uuid);

        // reset new workorder for next time
        this.newWorkOrder = {};
      },

      //load tenant's species list
      async loadTenantSpecies(tenant_uuid) {
        // get species
        var res_species = await Tenants.getTenantSpecies(tenant_uuid, {
          active: true,
        });

        if (res_species) {
          if (!(res_species.length > 0)) {
            console.log('tenant species list was empty');
            return;
          }

          //get just the species uuids
          var speciesUUIDs = res_species.map((x) => x.species_uuid);

          //this should probable just be called for each uuid rather than as a group so that
          //there doesn't have to be the rematching stuff
          //translate the uuids in the master list to get common and latin name

          var res_lookup = await Tenants.getMasterSpeciesBySpeciesLookup({
            species_uuids: speciesUUIDs,
          });

          if (res_lookup) {
            //save species response to species data table
            this.speciesList = res_lookup;
            //this rematches the tenant list uuid to the translated master list display
            //this is for alt name display and view purposes, but could probably be done in a much smarter way
            this.speciesList.forEach((row) => {
              var match = res_species.find((tenantSpecies) => {
                return tenantSpecies.species_uuid === row.uuid;
              });
              row.tenantSpeciesUUID = match.uuid;
              //if match has an alternate common name, make the row common name that
              row.common_name = match.alt_common_name
                ? match.alt_common_name
                : row.common_name;
              row.active = match.active;
            });
          } else {
            console.log('error doing master species lookup');
          }
        } else {
          console.log('could not load tenant species');
        }
      },
      matchWorkTasksAndSpecies: function () {
        this.worktasks.forEach((entry) => {
          if (this.trees && this.trees.length) {
            this.trees.forEach((tree) => {
              if (entry.tree_uuid == tree.uuid) {
                this.$set(
                  entry,
                  'species',
                  this.speciesList.find(
                    (x) => x.tenantSpeciesUUID == tree.tenant_species_uuid
                  )
                );
              }
            });
          }
        });
        this.$events.$emit('worktaskSpeciesUpdated');
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  .v-expansion-panel {
    width: auto;
  }

  .taskDots {
    margin-right: 0 !important;
  }

  .v-expansion-panel-content__wrap {
    padding: 16px 0 !important;
  }

  .v-expansion-panels > :first-child {
    margin-top: 16px !important;
  }
</style>
